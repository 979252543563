const outcomes = [{
    title: "The Icons",
    range: "Fashion",
    text: "G-SHOCK's iconic designs, including the best-selling octagonal 2100 alongside the square 5600 and triple-graph 6900, represent an evolution of the original G-SHOCK watches since 1983. In a short space of time, these models have made a powerful impact on street culture with their toughness, functionality, and bold design, setting a new standard for the entire G-SHOCK lineup.",
    url: "https://g-shock.co.uk/quiz-icons",
    video: "gshock-fashion-desktop.mp4",
    videoMobile: "gshock-fashion-desktop.mp4",
    recommendedProducts: [{
            title: "GA-B2100",
            image: "ga-b2100c-9aer.jpg",
            url: "https://g-shock.co.uk/?q=ga-b2100",
        },
        {
            title: "GA-2100",
            image: "ga-2100-1a_1_1.jpg",
            url: "https://g-shock.co.uk/?q=ga-2100",
        },
        {
            title: "GM-2100",
            image: "gm-2100-new.jpg",
            url: "https://g-shock.co.uk/?q=gm-2100",
        },
        {
            title: "DW-5600",
            image: "dw-5600bb-1er.jpg",
            url: "https://g-shock.co.uk/?q=dw-5600",
        },
    ],
},
{
    title: "GD-B500",
    range: "Sport",
    text: "G-SHOCK Sports Watches are built to withstand knocks and bumps even in the harshest environments, they also encompass daily health and fitness support functions. Featuring built in step tracking and a whole variety of individual sports tracking functions, helping you to monitor your fitness routines in the CASIO watches app.",
    url: "https://g-shock.co.uk/collections/sports-watches?utm_campaign=g-shock-quiz&utm_medium=referal&utm_source=quiz&utm_term=sport",
    video: "gshock-sport-desktop.mp4",
    videoMobile: "gshock-sport-desktop.mp4",
    recommendedProducts: [{
            title: "GBD-H2000",
            image: "h2000-1a9er.jpg",
            url: "https://g-shock.co.uk/?q=GBD-H2000",
        },
        {
            title: "GBD-200",
            image: "gbd-200-2er.jpg",
            url: "https://g-shock.co.uk/?q=GBD-200",
        },
        {
            title: "GBA-900",
            image: "gba-red.jpg",
            url: "https://g-shock.co.uk/?q=GBA-900",
        },
        {
            title: "DW-H5600",
            image: "5600-2er.jpg",
            url: "https://g-shock.co.uk/?q=DW-H5600",
        },
    ],
},
{
    title: "GWG-B1000 Mudmaster",
    range: "Master of G",
    text: "Master of G watches are ruggedly stylish, and admired among G-SHOCK enthusiasts. MOG watches are worn by the military with G-SHOCK also collaborating with the Ministry Of Defence on a number of collections. The watches are loved thanks to their extreme shock resistance and ability to perform in various environments around the world.",
    url: "https://g-shock.co.uk/?q=mud&utm_campaign=g-shock-quiz&utm_medium=referal&utm_source=quiz&utm_term=mog",
    video: "gshock-master-of-g-desktop.mp4",
    videoMobile: "gshock-master-of-g-desktop.mp4",
    recommendedProducts: [{
            title: "GWG-2000",
            image: "gwg-2000-1a1er.jpg",
            url: "https://g-shock.co.uk/?q=GWG-2000",
        },
        {
            title: "GW-9500",
            image: "1er-mudman.jpg",
            url: "https://g-shock.co.uk/?q=GW-9500",
        },
        {
            title: "GW-9400",
            image: "gw-9400-ps-ps_1_.jpg",
            url: "https://g-shock.co.uk/?q=GW-9400",
        },
        {
            title: "GG-B100",
            image: "gg-b100-1a9.jpg",
            url: "https://g-shock.co.uk/?q=GG-B100",
        },
    ],
},
{
    title: "GM-2110D Steel Edition",
    range: "Metal",
    text: "Offering smart yet adaptable premium timepieces, the metal collection boasts show stopping sleek designs that are bound to be noticed in any outfit or occasion whilst also offering fantastic adaptability thanks to the sheer level of durability, quality and shock resistance each one provides.",
    url: "https://g-shock.co.uk/collections/metal?utm_campaign=g-shock-quiz&utm_medium=referal&utm_source=quiz&utm_term=metal",
    video: "gshock-metal-desktop.mp4",
    videoMobile: "gshock-metal-desktop.mp4",
    recommendedProducts: [{
            title: "GST",
            image: "gstd_1.jpg",
            url: "https://g-shock.co.uk/?q=GST",
        },
        {
            title: "GM-2110D",
            image: "black-gm-b.jpg",
            url: "https://g-shock.co.uk/?q=gm-2110D",
        },
        {
            title: "GMW",
            image: "gmw-b5000d-1er.jpg",
            url: "https://g-shock.co.uk/?q=gmw",
        },
        {
            title: "MTG",
            image: "prb-plp.jpg",
            url: "https://g-shock.co.uk/?q=mtg",
        },
    ],
},
];

export default outcomes;