const questions = [{
    question: "Time to head out in the cold - What's their outfit of choice?",
    options: [{
            title: "Smart",
            text: "Suited up ready - from the boardroom to the Christmas markets.",
            outcomePoints: [3],
        },
        {
            title: "Casual",
            text: "Your go to trainers and matching coat.",
            outcomePoints: [0],
        },
        {
            title: "Action",
            text: "Geared up for your latest wintery outdoor adventure.",
            outcomePoints: [2],
        },
        {
            title: "Athletic",
            text: "Kitted, with your stopwatch and leg warmers at the ready.",
            outcomePoints: [1],
        },
    ],
},
{
    question: "It's the weekend. What are they up to this Christmas?",
    options: [{
            title: "Exploring",
            text: "Discovering nature in the snow.",
            outcomePoints: [2],
        },
        {
            title: "Training",
            text: "In the gym, burning off the turkey.",
            outcomePoints: [1],
        },
        {
            title: "Shopping",
            text: "Picking up the Christmas essentials. ",
            outcomePoints: [0],
        },
        {
            title: "Socialising",
            text: "Time for Christmas parties, mulled wine and Secret Santa.",
            outcomePoints: [3],
        },
    ],
},
{
    question: "They're at the Cinemas waiting in line to watch an iconic Christmas blockbuster - but which one will they choose?",
    options: [{
            title: "Action",
            text: "Polar missions to rescue Santa.",
            outcomePoints: [3],
        },
        {
            title: "Adventure",
            text: "Discovering a new city at Christmas.",
            outcomePoints: [2],
        },
        {
            title: "Romance",
            text: "The perfect match for you and your partner.",
            outcomePoints: [1],
        },
        {
            title: "Comedy",
            text: "Left home alone to protect the house.",
            outcomePoints: [0],
        },
    ],
},
{
    question: "What colour palette best describes who you're buying for?",
    options: [
        { 
            title: "Bold", 
            text: "Unique & practical.", 
            outcomePoints: [1] 
        },
        {
            title: "Military",
            text: "Olive greens and stealthy blacks.",
            outcomePoints: [2],
        },
        {
            title: "Subtle",
            text: "Sleek metal finishes & subdued tones.",
            outcomePoints: [3],
        },
        {
            title: "On Trend",
            text: "The latest trending colours.",
            outcomePoints: [0],
        },
    ],
},
{
    question: "What tech do they want on their wrist going into the New Year?",
    options: [{
            title: "Data",
            text: "GPS, step tracking, calorie burn - New Year, new me.",
            outcomePoints: [1],
        },
        {
            title: "Tools",
            text: "Compass, Altimeter & Barometer - Essential when exploring the snowy wilderness.",
            outcomePoints: [2],
        },
        {
            title: "The Works",
            text: "World Time updates - Built in phone finder and Solar Power is must.",
            outcomePoints: [0],
        },
        {
            title: "Style over tech",
            text: "Time to try a new style.",
            outcomePoints: [3],
        },
    ],
},
];

export default questions;
