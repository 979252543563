import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Home = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate(); // Import useNavigate from react-router-dom

  useEffect(() => {
    const updateWindowWidth = () => {
      const newWindowWidth = window.innerWidth;
      if ((windowWidth <= 768 && newWindowWidth > 768) || (windowWidth > 768 && newWindowWidth <= 768)) {
        setWindowWidth(newWindowWidth);
        document.getElementById('headerVideo').load();
      }
    };

    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, [windowWidth]);
  
  const handleGetStarted = () => {
    // Apply the fade-out class to section#hero
    const heroSection = document.getElementById('hero');
    heroSection.classList.add('fade-out');

    // After 0.5 seconds, navigate to /question
    setTimeout(() => {
      navigate('/question');
        }, 500); // Delay for 0.5 seconds (500ms)
    };

    return (
        <section id="hero" className="lg:min-h-screen flex md:items-center relative z-20 overflow-hidden">
            <div className="hero-image absolute inset-0 fade-in-slower">
                <video className="w-full h-auto md:h-full object-cover object-center lg:object-cover lg:object-top" id='headerVideo' muted playsInline loop autoPlay>
                    <source src={windowWidth <= 768 ? 'gshock-intro-mobile.mp4' : 'gshock-intro-desktop.mp4'} type="video/mp4" />
                </video>
            </div>

            <div className="no-pb no-pt lg:min-h-screen flex w-full">
                <div className="grid gap-0 lg:grid-cols-12 w-full">
                    <div className="col-span-6 text-left min-h-screen flex items-end lg:items-center lg:mb-0 relative z-10 text-white px-5 md:px-8 xl:px-16">
                    <div className="mobile-fade mobile-fade-top absolute top-0 left-0 right-0 h-32 bg-gradient-to-b from-black to-transparent z-0 lg:hidden opacity-80"></div>
                    <div className="mobile-fade absolute bottom-0 left-0 right-0 h-[40rem] bg-gradient-to-b from-transparent to-black z-0 lg:hidden"></div>
                    <div className="desktop-fade absolute bottom-0 left-0 top-0 opacity-90 w-[70vw] bg-gradient-to-r from-black to-transparent z-0 hidden lg:block"></div>
                        <div className="caption flex fade-in-slower flex-col space-y-3 md:space-y-6 lg:max-w-screen-sm text-center lg:text-left lg:pb-0 z-10 relative fade-in pb-24">
                            <h1 className="font-heading lg:tracking-wide uppercase text-[1.75rem] leading-[1.1] md:leading-tight xl:leading-tight md:text-5xl xl:text-6xl 2xl:text-[80px] 2xl:leading-none">
                                What G-SHOCK <span className="inline md:inline">are they?</span>
                            </h1>
                            <p className="text-[15px] sm:text-base md:text-xl xl:text-xl">
                            There's a G-SHOCK for everyone this Christmas. Take our profiler quiz and answer a few quick questions to find the perfect tough timepiece to make the perfect gift.
                            </p>
                            <Link to="#" onClick={handleGetStarted}>
                                <button className="button button-secondary self-start w-full lg:w-auto">
                                Get Started
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;