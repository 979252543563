
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const Result = ({ finalResult }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    const [showAnimation, setShowAnimation] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const updateWindowWidth = () => {
          const newWindowWidth = window.innerWidth;
          if ((windowWidth <= 768 && newWindowWidth > 768) || (windowWidth > 768 && newWindowWidth <= 768)) {
            setWindowWidth(newWindowWidth);
            document.getElementById('resultVideo').load();
          }
        };
    
        window.addEventListener('resize', updateWindowWidth);
    
        return () => {
          window.removeEventListener('resize', updateWindowWidth);
        };
      }, [windowWidth]);

    useEffect(() => {
        setIsVisible(false);

        setTimeout(() => {
            setShowAnimation(true);
        }, 0);

        setTimeout(() => {
            setIsVisible(true);
        }, 500);
    }, []);

    useEffect(() => {

        if (finalResult.length === 0) {
            navigate('/') //if no results yet - return to the home
        }

    }, [finalResult])

    const result = finalResult.outcome;

    return (
        finalResult.length !== 0 &&

        <div className={`opacity-0 ${isVisible ? 'opacity-100' : 'opacity-0'} ${showAnimation && 'duration-300'}`}>
            <section className={`overflow-hidden`}>
                <div className="grid gap-0 grid-cols-1 lg:grid-cols-12 w-full">
                    <div className="col-span-6 order-2 lg:order-1 text-left  flex items-center lg:mb-0 relative z-10 text-white px-5 md:px-8 xl:px-16 py-5 lg:py-0 -mt-20 lg:-mt-0">
                        <div className="fade-me-slower caption flex flex-col w-full space-y-4 md:space-y-6 lg:space-y-4 xl:space-y-6 py-5 lg:py-24 lg:pb-20 max-w-screen-md fade-in">
                            <p className="text-lg font-bold md:text-xl xl:text-2xl uppercase border-b-2 border-white border-opacity-25 pb-1 text-casio-light-grey">
                                Based on your answers, we’d recommend
                            </p>
                            <h2 className="font-heading uppercase leading-[1.125] text-3xl md:leading-none md:text-4xl xl:leading-[1.125] xl:text-5xl">
                                {result.title}
                            </h2>
                            <p className="text-sm md:text-lg lg:text-base xl:text-xl">
                                {result.text}
                            </p>
                            <a href={result.url} className="button button-secondary self-start w-full lg:w-auto">View the {result.range} range</a>
                        </div>
                    </div>
                    <div className="col-span-6 order-1 lg:order-2 fade-in-slower">
                        <div className="overflow-hidden w-full relative">
                            <div className="h-32 w-full absolute top-0 bg-gradient-to-b from-black to-transparent opacity-50"></div>
                            <div className="h-40 w-full absolute bottom-0 bg-gradient-to-b from-transparent to-black lg:hidden"></div>
                            <div className="triangle-wrap opacity-0 lg:opacity-100">
                                <div className="triangle"></div>
                            </div>
                            <div className="w-full fade-me-slower h-auto lg:h-full object-cover lg:object-cover lg:object-center max-h-[550px] md:max-h-[600px] lg:max-h-[550px] xl:max-h-[700px]" width="640" height="480" muted playsInline loop autoPlay>
                                <video id="resultVideo" className="w-full h-auto lg:h-full object-cover lg:object-cover lg:object-center max-h-[550px] md:max-h-[600px] lg:max-h-[550px] xl:max-h-[700px]" width="640" height="480" muted playsInline loop autoPlay>
                                    <source src={windowWidth < 768 ? result.videoMobile : result.video} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="overflow-hidden bg-casio-red text-white p-5 xl:p-6">
                <div className="flex flex-col lg:flex-row items-center justify-center lg:space-x-8">
                    <div className="flex flex-col lg:flex-row items-center justify-center text-center lg:text-left mb-2 lg:mb-0">
                        <img src="10-off.png" className="mb-4 lg:mb-0 lg:mr-4 w-24 md:w-32 lg:w-24 xl:w-32 " alt="Watch Name" />
                        <p className="font-bold text-lg leading-tight md:text-xl lg:text-lg xl:text-xl uppercase">Participate in our survey and enjoy a 10% discount on your upcoming G-SHOCK purchase</p>
                    </div>
                    <a href="https://www.surveymonkey.co.uk/r/WhichG-SHOCKareyou" className="button block button-secondary self-start w-full lg:w-auto whitespace-nowrap">Take the survey</a>
                </div>
            </section>

            <section className="overflow-hidden bg-white text-black p-5 md:p-8 xl:p-16 space-y-5 md:space-y-8 xl:space-y-16">
                <h2 className="text-center font-heading uppercase leading-[1.125] text-2xl md:leading-none md:text-4xl xl:leading-[1.125] xl:text-5xl">Featured G-SHOCK {result.range} watches</h2>
                <div className="grid grid-cols-2 md:grid-cols-12 gap-5 md:gap-8 xl:gap-12 w-full text-center uppercase font-bold text-xl md:text-2xl">
                    {
                        result.recommendedProducts.map((product, index) => (
                            <div className="md:col-span-6 lg:col-span-3">
                                <a href={product.url} className="watch-card p-3 md:p-6 lg:p-4 xl:p-6 flex flex-col items-center border-casio-light-grey border-[3px] relative overflow-hidden">
                                    <span className="border-casio-light-grey border-[3px] block absolute -bottom-7 -right-7 w-12 h-12 rotate-45 bg-white"></span>
                                    <img src={product.image} className="mb-3 md:mb-6" alt="Watch Name" />
                                    <h3 className="">{product.title}</h3>
                                </a>
                            </div>
                        ))
                    }
                </div>
                <div className="flex justify-center">
                    <a href={result.url} className="button block button-primary self-start w-full lg:w-auto">View the {result.range} range</a>
                </div>
            </section>
        </div>
    );
};

export default Result;